import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function usePermissionsRepository() {
  const permissions = ref([]);
  const totalResults = ref(0);
  const loading = ref(false);

  const getPermissions = async () => {
    loading.value = true;
    let response = await API.Admin.Permissions.get();
    if (response.data.Success) {
      permissions.value = response.data.List;
      totalResults.value = response.data.TotalResults;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Permissions",
        type: "warning",
      });
    }
  };

  return {
    permissions: readonly(permissions),
    loading: readonly(loading),
    getPermissions,
    totalResults,
  };
}
