<template>
  <slideover title="Create Role">
    <form class="flex h-full flex-col bg-white">
      <div class="flex flex-1 flex-col justify-between">
        <div class="divide-y divide-gray-200 px-4 sm:px-6">
          <div class="space-y-6 pt-6 pb-5">
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="role-name"
                  id="role-name"
                  v-model="state.name"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Description
              </label>
              <div class="mt-1">
                <textarea
                  id="description"
                  name="description"
                  v-model="state.description"
                  rows="4"
                  class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Permissions
              </label>
              <div class="mt-1">
                <el-select
                  v-model="state.permission"
                  multiple
                  collapse-tags
                  clearable
                  placeholder="Select Permission"
                  style="width: 100%"
                >
                  <el-option
                    v-for="permission in state.permissions"
                    :key="permission.Id"
                    :label="permission.Name"
                    :value="permission.Id"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
          @click="onClose"
        >
          Cancel
        </button>
        <button
          type="button"
          @click="onSubmit"
          class="focus:outline-none ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </form>
  </slideover>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";

import Slideover from "@/common/components/slideover";
import usePermissionsRepository from "@/composable/usePermissionsRepository";
// import useRolesRepository from "@/composable/useRolesRepository";

export default {
  components: {
    Slideover,
  },
  setup(_, { emit }) {
    const { permissions, loading, getPermissions, totalResults } =
      usePermissionsRepository();
    // const { saveRole } = useRolesRepository();
    const state = reactive({
      count: 0,
      name: "",
      description: "",
      permission: [],
      permissions: permissions,
    });

    const onSubmit = () => {
      let ids = [];

      state.permission.forEach((permission) => {
        ids.push({
          id: permission,
        });
      });

      // saveRole({
      //   name: state.name,
      //   description: state.description,
      //   permissions: ids,
      // });
      emit("onRoleCreated", {
        name: state.name,
        description: state.description,
        permissions: ids,
      });
    };

    const onClose = () => {
      emit("onClose", true);
    };

    onMounted(() => {
      getPermissions();
    });

    return {
      state,
      onSubmit,
      ...toRefs(state),
      permissions,
      loading,
      totalResults,
      onClose,
    };
  },
};
</script>

<style lang="scss" scoped></style>
