<template>
  <div v-loading="loading" class="layout">
    <Title name="Roles" />

    <div class="flex justify-between">
      <Search />
      <div class="flex justify-end">
        <button
          type="button"
          @click="openSlide"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Create
        </button>
      </div>
    </div>

    <div class="relative mt-4 flex flex-col" v-if="roles.length > 0">
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div
            class="overflow-hidden border border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Permissions
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="role in roles" :key="role.Id" class="text-left">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ role.Name }}
                      </div>
                      <div class="max-w-sm truncate text-sm text-gray-500">
                        {{ role.Description }}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 text-sm text-gray-500">
                    <span
                      v-for="permission in role.Permissions"
                      :key="permission.Id"
                      class="ml-2 inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                    >
                      {{ permission.Name }}
                    </span>
                  </td>
                  <td
                    class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="onEdit(role)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Delete',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="openModal(role.Id)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-red-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                    >
                      <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <NewRole
    :show="state.openCreate"
    @onClose="closeSlide"
    @onRoleCreated="onRoleCreated"
  />

  <EditRole
    v-if="Object.entries(state.role).length > 0"
    :show="state.toggleEdit"
    :role="state.role"
    @onClose="closeEditSlide"
    @onRoleUpdated="onRoleUpdated"
  />
  <DeleteModal
    :show="state.openModal"
    @onClose="closeModal"
    @onSubmit="onDeleteRole"
  />
</template>

<script>
import { reactive, onMounted } from "vue";
import { TrashIcon, PencilIcon } from "@heroicons/vue/outline";

import useRolesRepository from "@/composable/useRolesRepository";
import Title from "@/common/components/title";
import Pagination from "@/common/components/pagination";
import Search from "@/common/components/search";

import NewRole from "./components/_new";
import EditRole from "./components/_edit";
import DeleteModal from "./components/_deleteModal";

import { directive } from "vue-tippy";

import "tippy.js/animations/scale.css";

export default {
  name: "Roles",
  components: {
    Pagination,
    Search,
    Title,
    TrashIcon,
    PencilIcon,
    NewRole,
    EditRole,
    DeleteModal,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const {
      loading,
      roles,
      totalResults,
      getRoles,
      saveRole,
      editRole,
      deleteRole,
    } = useRolesRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      search: "",
      totalResults: totalResults,
    });

    const state = reactive({
      openCreate: false,
      toggleEdit: false,
      openModal: false,
      role: {},
      selectedRoleId: null,
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getRoles(pagination.page, pagination.pageSize, pagination.search);
    };

    const openSlide = () => {
      state.role = {};
      state.openCreate = true;
    };

    const closeSlide = () => {
      state.openCreate = false;
    };

    const closeModal = () => {
      state.openModal = false;
    };

    const openModal = (id) => {
      state.openModal = true;
      state.selectedRoleId = id;
    };

    const onDeleteRole = () => {
      deleteRole(state.selectedRoleId);
      closeModal();
      state.role = {};
    };

    const onRoleCreated = (role) => {
      saveRole(role);
      state.role = {};
      closeSlide();
    };

    const onEdit = (role) => {
      state.role = role;
      state.toggleEdit = true;
    };

    const closeEditSlide = () => {
      state.toggleEdit = false;
      state.role = {};
    };

    const onRoleUpdated = (role) => {
      editRole(role);
      closeEditSlide();
      state.role = {};
    };

    onMounted(() => {
      getRoles(pagination.page, pagination.pageSize, pagination.search);
    });

    return {
      state,
      roles,
      loading,
      pagination,
      openSlide,
      closeSlide,
      closeModal,
      openModal,
      onDeleteRole,
      onChangePage,
      totalResults,
      onRoleCreated,
      onEdit,
      closeEditSlide,
      onRoleUpdated,
    };
  },
};
</script>

<style scoped>
.p-8 {
  padding: 32px;
}

.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
</style>
